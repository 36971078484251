export class SessionStorageHelper {
    public data: string;

    constructor(private readonly id: string) {
        this.update();
    }

    public clear(): void {
        if (this.hasData) {
            sessionStorage.removeItem(this.id);
        }
    }

    public set(data: string): void {
        sessionStorage.setItem(this.id, data);
        this.update();
    }

    public get hasData(): boolean {
        return !!this.data;
    }

    private update(): void {
        this.data = sessionStorage.getItem(this.id);
    }
}
