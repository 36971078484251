//
// A super simple template engine for situations where we don't need a fully fledged library.
//
// Current support:
// - Bare-bones conditional blocks
// - Property values must be primitive types (string, number)
//
// Note: white space removal is quite brutal.
//

interface ITemplateProperties {
    [key: string]: string;
}

function parseBlock(properties: ITemplateProperties, p1: string, p2: string): string {
    return p1 in properties ? p2 : '';
}

function parseTag(properties: ITemplateProperties, match: string, p1: string): string {
    return p1 in properties ? properties[p1] : match;
}

/**
 * Render a template.
 * @param template  The raw template string.
 * @param properties  List of key/value pairs to apply to the template.
 * @param tidy  Tidy up the returned string. Default: true.
 */
export function render(template: string, properties: ITemplateProperties, tidy: boolean = true): string {
    let output = template;

    // Find any conditional blocks. If the tag name has a corresponding property, keep the contents.
    output = output.replace(/{{#(\w+)}}([\s\S]*?){{\/\1}}/gm, (match, p1, p2) => parseBlock(properties, p1, p2));

    // Find any tags and replace them with the corresponding property.
    output = output.replace(/{{(\w+)}}/g, (match, p1) => parseTag(properties, match, p1));

    // Tidy up: remove any line feeds, carriage returns or whitespace which is >= two spaces.
    output = tidy ? output.replace(/\r?\n|\r|[ ]{2,}/g, '') : output;

    return output;
}
