import { FauxSizeMap, SizeMap } from '@entities/SizeMapTypes';

// Note on the Map object: In an ideal world we'd just instantiate a new Map object, passing an iterable into the constructor.
// However, while IE11 has basic support for the Map object, it doesn't support constructor arguments. Typical.
// These helper functions essentially polyfill this behaviour for us. It adds bloat, but it works.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map#Browser_compatibility

/**
 * Convert a Map object to a traditional name/value pair object.
 * @param map  Map object to convert.
 */
export function mapToObject(map: SizeMap): FauxSizeMap {
    const output: FauxSizeMap = {};

    map.forEach((value, key) => { output[key] = value; });

    return output;
}

/**
 * Convert a traditional name/value pair object to a Map object.
 * @param obj  Object to convert.
 */
export function objectToMap(obj: FauxSizeMap): SizeMap {
    const output: SizeMap = new Map();

    Object.keys(obj).forEach(key => { output.set(key, obj[key]); });

    return output;
}
