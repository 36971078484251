import * as imageHelpers from '@functions/image-helpers';
import * as siteHelpers from '@functions/site-helpers';
import { debounce } from '@utils/debounce';
import * as $ from 'jquery';
import { Parallax } from './Parallax';
import { ParallaxImage } from './ParallaxImage';
import { ParallaxTiledImage } from './ParallaxTiledImage';
import { ParallaxType } from './ParallaxType';

interface IScrollPerformanceHelpers {
    scrollY: number;
    ticking: boolean;
    viewportHeight: number;
    setScrollY: () => void;
    setViewportHeight: () => void;
}

namespace ParallaxBackgrounds {
    const root = document.documentElement;
    const sections = $('.section.has-bg-image.bg-pos-parallax').get().filter(section => isValidParallaxContainer(section));
    const parallaxRepository: Parallax[] = [];

    const helpers: IScrollPerformanceHelpers = {
        scrollY: 0,
        ticking: false,
        viewportHeight: 0,
        setScrollY() {
            this.scrollY = window.pageYOffset;
        },
        setViewportHeight() {
            this.viewportHeight = window.innerHeight;
        }
    };

    function getParallaxesOfType<T extends Parallax>(type: ParallaxType): T[] {
        return parallaxRepository.filter(parallax => parallax.type === type) as T[];
    }

    function globalResize(): void {
        setScrollbarWidth();

        // Note: aside from its initial call, globalResize is nested inside a debounced handler. Bear this in mind should any issues
        // arise with the following function not firing in time for the parallax scroll event.
        helpers.setViewportHeight();

        const parallaxImageRepo = getParallaxesOfType<ParallaxImage>(ParallaxType.Image);
        const parallaxTiledImageRepo = getParallaxesOfType<ParallaxTiledImage>(ParallaxType.TiledImage);

        // Parallax image instances.
        parallaxImageRepo.forEach(parallax => {
            parallax.update();

            const imageSize = imageHelpers.getImageSize(parallax.imageHelper.key, parallax.imageHelper.targetDimensions.safeWidth);

            parallax.imageHelper.load(imageSize);
            imageHelpers.updateImageSizeMap(parallax.imageHelper.key, imageSize);
        });

        // Parallax tiled image instances.
        parallaxTiledImageRepo.forEach(parallax => parallax.update());

        // Trigger the scroll event.
        globalScroll();
    }

    function globalScroll(): void {
        // Note: Edge & Safari appear to have an issue with requestAnimationFrame whereby callbacks aren't processed before the next paint.
        // According to Microsoft the issue is fixed on Edge, but it's obvious from their example and testing that this isn't the case.
        // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15469349/
        // https://bugs.webkit.org/show_bug.cgi?id=177484
        // This block may seen a bit overkill, but it gives the aforementioned browsers the best chance at rendering things smoothly.

        helpers.setScrollY();

        if (!helpers.ticking) {
            window.requestAnimationFrame(() => {
                for (let i = 0, j = parallaxRepository.length; i < j; i++) {
                    parallaxRepository[i].scroll(helpers.scrollY, helpers.viewportHeight);
                }

                helpers.ticking = false;
            });

            helpers.ticking = true;
        }
    }

    function isValidParallaxContainer(container: HTMLElement): boolean {
        const a = container.querySelector('.parallax-wrapper');
        const b = container.querySelector('.parallax-wrapper .parallax-inner');

        return !!a && !!b;
    }

    function setScrollbarWidth(): void {
        // 100vw doesn't take scrollbar width into account. We can compensate for this using a CSS variable. Unsupportive browsers can suffer.
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        root.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    }

    export function init(): void {
        if (sections.length) {
            setScrollbarWidth();

            sections.forEach(section => {
                const wrapper = section.querySelector('.parallax-wrapper') as HTMLElement;

                switch ((wrapper.dataset.parallaxType || '').trim().toLowerCase()) {
                    case 'image':
                        parallaxRepository.push(new ParallaxImage(section));
                        break;
                    case 'tiled-image':
                        parallaxRepository.push(new ParallaxTiledImage(section));
                        break;
                }
            });

            if (parallaxRepository.length) {
                globalResize();

                window.addEventListener('resize', debounce(globalResize, 200), siteHelpers.usePassiveIfSupported);
                document.addEventListener('scroll', globalScroll, siteHelpers.usePassiveIfSupported);
            }
        }
    }
}

ParallaxBackgrounds.init();
