import * as $ from 'jquery';

export class CommentArea {
    private readonly area: JQuery<HTMLElement>;
    private readonly buttonMore: JQuery<HTMLElement>;
    private readonly comments: JQuery<HTMLElement>;
    private hiddenComments: JQuery<HTMLElement>;
    private readonly threshold: number;

    constructor(element: HTMLElement, threshold: number = 5) {
        this.area = $(element);
        this.buttonMore = this.area.find('.comments .btn');
        this.comments = this.area.find('.comment');
        this.hiddenComments = $();
        this.threshold = threshold < 1 ? 1 : threshold;

        if (this.comments.length > this.threshold) {
            this.hiddenComments = this.comments.filter(`:gt(${this.threshold - 1})`).addClass('hidden');
            this.updateButtonMore();

            this.buttonMore.removeClass('hidden').on('click', (event: JQuery.Event) => {
                event.preventDefault();
                this.revealComments(this.threshold);
            });
        }
    }

    private revealComments(amount: number): void {
        this.comments.filter(':not(:visible)').slice(0, amount).removeClass('hidden');
        this.hiddenComments = this.comments.filter(':not(:visible)');
        this.updateButtonMore();
    }

    private updateButtonMore(): void {
        if (!!this.hiddenComments.length) {
            const revealCount = this.hiddenComments.length < this.threshold ? this.hiddenComments.length : this.threshold;

            this.buttonMore.find('span').text(revealCount.toString());
        } else {
            this.buttonMore.addClass('hidden');
        }
    }
}
