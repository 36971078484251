import { AdaptiveBgImageHelper } from '@entities/AdaptiveBgImageHelper';
import { TargetDimensionArgs } from '@entities/TargetDimensionArgs';
import * as imageHelpers from '@functions/image-helpers';

export class ImageContainer {
    public containerWidth: number;
    public containerHeight: number;
    public imageHelper: AdaptiveBgImageHelper;

    constructor(private readonly container: HTMLElement) {
        const imageProps = imageHelpers.getImagePropsFromDom(this.container);

        this.imageHelper = new AdaptiveBgImageHelper(this.container, imageProps);
    }

    public setContainerDimensions(): void {
        this.containerWidth = this.container.offsetWidth;
        this.containerHeight = this.container.offsetHeight;

        const targetDimensionArgs = [this.containerWidth, this.containerHeight, this.imageHelper.naturalWidth, this.imageHelper.naturalHeight];

        this.imageHelper.targetDimensions = imageHelpers.getTargetImageDimensionsForContainer(...targetDimensionArgs as TargetDimensionArgs);
    }
}
