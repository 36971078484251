import * as $ from 'jquery';
import { EmbeddedAssetType } from './EmbeddedAssetType';

//  Toggle logic
//  |
//  |---> Is the container visible?
//        |
//        |---> Y ---> Is asset initialised?
//        |            |
//        |            |---> Y ---> Do nothing
//        |            |
//        |            |---> N ---> Initialise
//        |
//        |---> N ---> Is asset initialised?
//                     |
//                     |---> Y ---> Destroy
//                     |
//                     |---> N ---> Do nothing

export abstract class EmbeddedAsset {
    public readonly assetType: EmbeddedAssetType;

    protected readonly container: JQuery<HTMLElement>;
    protected readonly assetId: number;
    protected isInitialised: boolean;
    protected isVisible: boolean;

    constructor(assetType: EmbeddedAssetType, container: HTMLElement) {
        this.assetType = assetType;
        this.container = $(container);
        this.assetId = +this.container.data('asset-id');
        this.isVisible = false;
        this.isInitialised = false;
    }

    public abstract create(): void;
    public abstract destroy(): void;

    public toggle(): void {
        this.isVisible = this.container.is(':visible');

        if (this.isVisible && !this.isInitialised) {
            this.create();
        } else if (!this.isVisible && this.isInitialised) {
            this.destroy();
        }
    }
}
