import * as siteHelpers from '@functions/site-helpers';
import { ParallaxType } from './ParallaxType';

const minViewportWidth = Math.min(...APP_EXTERNAL.config.grid.breakpoints);
const ignoreViewportWidth = false; // TESTING - set to false for production
const disableIE = true;

export abstract class Parallax {
    public type: ParallaxType;

    protected readonly container: HTMLElement;
    protected containerWidth: number;
    protected containerHeight: number;
    protected readonly inner: HTMLElement;
    protected readonly wrapper: HTMLElement;

    private areaDifference: number;
    private containerOffsetY: number;
    private hasBleed: boolean;
    private isActive: boolean;
    private innerHeight: number;
    private isParked: boolean;
    private isVisible: boolean;

    constructor(container: HTMLElement) {
        this.container = container;
        this.wrapper = this.container.querySelector('.parallax-wrapper') as HTMLElement;
        this.inner = this.wrapper.querySelector('.parallax-inner') as HTMLElement;
        this.isActive = false;
        this.isParked = false;
    }

    public scroll(scrollY: number, viewportHeight: number): void {
        if (!this.isActive) {
            return;
        }

        const viewportBottom = scrollY + viewportHeight;

        // Create a range from 0 to 1 representing the container entering and leaving the viewport.
        const areaRatio = (viewportBottom - this.containerOffsetY) / (this.containerHeight + viewportHeight);

        // Use the fraction to create a range between 0 and 100, which will represent the total distance the parallax will travel over a full
        // viewport scroll. We want to pivot around the vertical centre of the image, so we'll also offset the result by half.
        const range = 50 - (areaRatio * 100);

        // Align the parallax to the centre of the viewport.
        const centrePoint = (scrollY - this.containerOffsetY) - ((this.innerHeight / 2) - (viewportHeight / 2));

        // Pivot around the centre point.
        const offsetY = centrePoint + ((this.areaDifference / 100) * range);

        // Check if the parallax is inside the visible viewport or not. We don't want to trigger a repaint needlessly.
        const inViewport = (this.containerOffsetY + this.containerHeight) > scrollY && this.containerOffsetY < viewportBottom;

        if (inViewport) {
            this.inner.style.transform = `translate3D(0, ${offsetY.toFixed(2)}px, 0)`;
        }
    }

    public update(): void {
        const validViewportWidth = window.matchMedia(`(min-width: ${minViewportWidth}px)`).matches;

        this.containerOffsetY = this.container.offsetTop;
        this.containerWidth = this.wrapper.offsetWidth;
        this.containerHeight = this.wrapper.offsetHeight;
        this.innerHeight = this.inner.offsetHeight;
        this.areaDifference = this.innerHeight - this.containerHeight;
        this.hasBleed = this.innerHeight > this.containerHeight;

        // Note: offsetParent returns null if it or any of its descendents are hidden via the display style property.
        this.isVisible = this.wrapper.offsetParent !== null;

        this.isActive = this.isVisible
            && this.hasBleed
            && !siteHelpers.device.isMobile
            && !(disableIE && (siteHelpers.browser.isEdge || siteHelpers.browser.isIE11))
            && (validViewportWidth || ignoreViewportWidth);

        if (this.isActive && this.isParked) {
            this.inner.classList.remove('parked');
            this.isParked = false;
        } else if (!this.isActive && !this.isParked) {
            this.inner.classList.add('parked');
            this.inner.style.transform = null;
            this.isParked = true;
        }
    }
}
