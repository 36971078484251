import * as siteHelpers from '@functions/site-helpers';
import { debounce } from '@utils/debounce';
import * as $ from 'jquery';
import { CarouselAsset } from './CarouselAsset';
import { EmbeddedAsset } from './EmbeddedAsset';
import { EmbeddedAssetType } from './EmbeddedAssetType';
import { MapAsset } from './MapAsset';

const assets = $('.asset-embedded').get();
const assetRepository: EmbeddedAsset[] = [];

function getAssetsOfType<T extends EmbeddedAsset>(type: EmbeddedAssetType): T[] {
    return assetRepository.filter(asset => asset.assetType === type) as T[];
}

function globalResize() {
    assetRepository.forEach(asset => asset.toggle());
}

/**
 * Create a repository of embedded assets and attach event listeners to the window to handle resizing.
 */
export function init(): void {
    if (assets.length) {
        assets.forEach(asset => {
            switch ((asset.dataset.assetType || '').trim().toLowerCase()) {
                case 'carousel':
                    assetRepository.push(new CarouselAsset(asset));
                    break;
                case 'map':
                    assetRepository.push(new MapAsset(asset));
                    break;
            }
        });

        if (assetRepository.length) {
            globalResize();

            window.addEventListener('resize', debounce(globalResize, 200), siteHelpers.usePassiveIfSupported);
        }
    }
}
