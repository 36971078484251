import * as $ from 'jquery';

enum MagnificPopupType {
    Iframe = 'iframe',
    Image = 'image',
    Inline = 'inline'
}

export class ZoomableAsset {
    private readonly asset: JQuery<HTMLElement>;
    private readonly parent: JQuery<HTMLElement>;
    private readonly type: string;
    private readonly url: string;

    constructor(element: HTMLElement) {
        this.asset = $(element);
        this.parent = this.asset.parent();
        this.type = this.deriveType();
        this.url = element.getAttribute('href');

        this.asset.magnificPopup({
            type: this.magnificType,
            disableOn: () => this.testContainer()
        });
    }

    private deriveType(): string {
        const match = /asset-(\w+)/.exec(this.parent.attr('class') || '');

        return match !== null && match.length > 1 ? match[1] : 'inline';
    }

    private testContainer(): boolean {
        return !(this.parent.width() >= $(window).width() - APP_EXTERNAL.config.grid.gutter);
    }

    private get magnificType(): string {
        let result = '';

        switch (this.type) {
            case 'image':
                result = MagnificPopupType.Image;
                break;
            case 'map':
            case 'video':
                result = MagnificPopupType.Iframe;
                break;
            default:
                result = MagnificPopupType.Inline;
                break;
        }

        return result;
    }
}
