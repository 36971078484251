import { ParallaxType } from '@components/parallax-backgrounds/ParallaxType';
import { AdaptiveBgImageHelper } from '@entities/AdaptiveBgImageHelper';
import { IImageCoverOptions } from '@entities/IImageCoverOptions';
import { TargetDimensionArgs } from '@entities/TargetDimensionArgs';
import * as imageHelpers from '@functions/image-helpers';
import { Parallax } from './Parallax';

const imageCoverOptions: IImageCoverOptions = {
    minVerticalOverflow: 200
};

export class ParallaxImage extends Parallax {
    public imageHelper: AdaptiveBgImageHelper;

    constructor(container: HTMLElement) {
        super(container);

        const imageProps = imageHelpers.getImagePropsFromDom(this.wrapper.parentElement);
        const imageContainer = this.inner.appendChild(document.createElement('div'));

        imageContainer.classList.add('image-container');

        this.type = ParallaxType.Image;
        this.imageHelper = new AdaptiveBgImageHelper(imageContainer, imageProps);
    }

    public setContainerDimensions(): void {
        this.containerWidth = this.wrapper.offsetWidth;
        this.containerHeight = this.wrapper.offsetHeight;

        const targetDimensionArgs = [this.containerWidth, this.containerHeight, this.imageHelper.naturalWidth, this.imageHelper.naturalHeight, imageCoverOptions];

        this.imageHelper.targetDimensions = imageHelpers.getTargetImageDimensionsForContainer(...targetDimensionArgs as TargetDimensionArgs);
        this.imageHelper.container.style.height = `${this.imageHelper.targetDimensions.height}px`;
    }

    public update(): void {
        this.setContainerDimensions();

        super.update();
    }
}
