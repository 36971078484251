import * as $ from 'jquery';
import { CommentArea } from './CommentArea';
import { CustomForm } from './CustomForm';
import { ZoomableAsset } from './ZoomableAsset';

// Add a custom pattern to Magnific Popup to handle our internal map URL.
$.magnificPopup.defaults.iframe.patterns.wsmap = {
    index: '//maps.apple.com',
    id: 'wsaid=',
    src: '/map/%id%'
};

/**
 * Initialise the various inline elements found on the site.
 */
export function init(): void {
    // Initialise zoomable assets (note: currently using magnific popup).
    $('.asset-zoom').get().map(x => new ZoomableAsset(x));

    // Set up custom form functionality.
    $('.form-custom').get().map(x => new CustomForm(x));

    // Set up any comment areas.
    $('.asset-comments').get().map(x => new CommentArea(x));

    // Append Bootstrap classes to tables generated by Redactor.
    $('.asset-text table').addClass('table table-bordered').wrap('<div class="table-responsive"></div>');
}
