/// <reference types="googlemaps" />

import * as templater from '@utils/templater';
import { EmbeddedAsset } from './EmbeddedAsset';
import { EmbeddedAssetType } from './EmbeddedAssetType';

// tslint:disable-next-line:interface-name
interface Window {
    [key: string]: any;
}

interface IMapData {
    postcode: string;
    coords: string;
    heading?: string;
    description?: string;
    zoomLevel: number;
}

declare var window: Window;

const infoWindowTemplate = `
    {{#heading}}
        <h3>{{heading}}</h3>
    {{/heading}}
    {{#description}}
        <p>{{description}}</p>
    {{/description}}
`;

const defaultMapOptions = {
    scrollwheel: false,
    gestureHandling: 'cooperative'
};

export class MapAsset extends EmbeddedAsset {
    private readonly mapContainer: JQuery<HTMLElement>;
    private readonly heading?: string;
    private readonly description?: string;
    private readonly latLng: google.maps.LatLng;
    private readonly zoomLevel: number;
    private readonly hasInfoWindow: boolean;

    private mapInstance?: google.maps.Map;
    private mapMarker?: google.maps.Marker;
    private mapListener?: google.maps.MapsEventListener;
    private mapInfoWindow?: google.maps.InfoWindow;

    constructor(container: HTMLElement) {
        super(EmbeddedAssetType.Map, container);

        const mapData = window[`DATA_MAP_${this.assetId}`] as IMapData;
        const coords = mapData.coords.split(',').map(x => parseFloat(x)) as any as [number, number];

        this.heading = mapData.heading || '';
        this.description = mapData.description || '';
        this.latLng = new google.maps.LatLng(coords[0], coords[1]);
        this.zoomLevel = mapData.zoomLevel || 16;
        this.hasInfoWindow = !!this.heading || !!this.description;

        this.mapContainer = this.container.find('.embed-responsive-item');
        this.mapInstance = null;
        this.mapMarker = null;
        this.mapInfoWindow = null;

        if (this.hasInfoWindow) {
            this.mapInfoWindow = new google.maps.InfoWindow({
                content: this.infoWindowHtml
            });
        }
    }

    public create(): void {
        this.mapInstance = new google.maps.Map(this.mapContainer.get()[0], this.mapOptions);
        this.mapMarker = new google.maps.Marker(this.markerOptions);
        this.mapListener = google.maps.event.addDomListener(window, 'resize', () => this.centraliseMap());

        if (this.hasInfoWindow) {
            this.mapMarker.addListener('click', () => this.openMapInfoWindowOnMarker(this.mapMarker));
        }

        this.isInitialised = true;
    }

    public destroy(): void {
        this.mapContainer.empty().removeAttr('style');
        this.mapInstance = null;

        google.maps.event.removeListener(this.mapListener);
        this.mapListener = null;

        this.mapMarker = null;
        this.isInitialised = false;
    }

    private centraliseMap(): void {
        this.mapInstance.setCenter(this.latLng);
    }

    private openMapInfoWindowOnMarker(marker: google.maps.Marker): void {
        this.mapInfoWindow.open(this.mapInstance, marker);
    }

    private get infoWindowHtml(): string {
        return templater.render(infoWindowTemplate, {
            heading: this.heading,
            description: this.description
        });
    }

    private get mapOptions(): google.maps.MapOptions {
        return {
            ...defaultMapOptions, ...{
                center: this.latLng,
                zoom: this.zoomLevel
            }
        } as google.maps.MapOptions;
    }

    private get markerOptions(): google.maps.MarkerOptions {
        return {
            map: this.mapInstance,
            position: this.latLng
        };
    }
}
