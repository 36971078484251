import { IImageProperties } from '@entities/IImageProperties';
import { ImageSize } from '@entities/ImageSize';
import { ITargetImageDimensions } from '@entities/ITargetImageDimensions';
import * as imageHelpers from '@functions/image-helpers';

export class AdaptiveBgImageHelper {
    public currentSize: ImageSize;
    public readonly naturalWidth: number;
    public readonly naturalHeight: number;
    public targetDimensions: ITargetImageDimensions;

    private readonly fileName: string;
    private readonly fileExtension: string;
    private image: HTMLImageElement;

    constructor(public readonly container: HTMLElement, properties: IImageProperties) {
        this.fileName = properties.fileName;
        this.fileExtension = properties.fileExtension || 'jpg';
        this.naturalWidth = +properties.naturalWidth;
        this.naturalHeight = +properties.naturalHeight || 0;

        this.image = new Image();
        this.image.onload = () => this.applyCached();
    }

    public applyCached(): void {
        if (!!this.image.src) {
            this.container.style.backgroundImage = `url('${this.image.src}')`;
            this.container.classList.add('bg-image-loaded');
        }
    }

    public load(imageSize: ImageSize, force: boolean = false): void {
        if (typeof this.currentSize === 'undefined' || imageSize.width > this.currentSize.width || force) {
            this.image.src = imageHelpers.getAssetUrl({
                columnSize: imageSize.columnSize,
                fileName: this.fileName,
                fileExtension: this.fileExtension
            });

            this.currentSize = imageSize;
            imageHelpers.updateImageSizeMap(this.key, imageSize);
        }
    }

    public get key(): string {
        return [this.fileName, this.fileExtension].join('.');
    }
}
