import { IAssetUrlProperties } from '@entities/IAssetUrlProperties';
import { IImageCoverOptions } from '@entities/IImageCoverOptions';
import { IImageProperties } from '@entities/IImageProperties';
import { ImageSize } from '@entities/ImageSize';
import { ITargetImageDimensions } from '@entities/ITargetImageDimensions';
import { SessionStorageHelper } from '@entities/SessionStorageHelper';
import * as mapHelpers from './map-helpers';
import * as siteHelpers from './site-helpers';

const assetUrlTemplate = `${APP_EXTERNAL.config.CDNUrl}/usercontent/img/col-{{columnSize}}/{{fileName}}.{{fileExtension}}`;
const imageSizes = Object.entries(APP_EXTERNAL.config.grid.imageSizes).map(x => new ImageSize(x[0], x[1]));
const pixelRatio = window.devicePixelRatio || 1;

const defaultImageCoverOptions: IImageCoverOptions = {
    minVerticalOverflow: 0,
    scale: 1
};

// Session storage is currently utilised by the following components/functions to prevent multiple versions of the same image
// being loaded unnecessarily, when all we actually want is the largest size. This is much the same way srcset works:
// - Adaptive background images
// - preloadImage()
const storageKey = 'ws-image-cache';
const useStorage = true;
const storage = useStorage ? new SessionStorageHelper(storageKey) : null;

// Wouldn't it be nice to pass an iterable to the Map constructor? Thanks to IE11 we can't. See map helpers for more info.
const imageSizeMap = useStorage && storage.hasData ? mapHelpers.objectToMap(JSON.parse(storage.data)) : new Map();

/**
 * Construct an asset URL by replacing tags in a pre-defined URL template with custom properties.
 * @param properties  List of properties to replace corresponding tags.
 */
export function getAssetUrl(properties: IAssetUrlProperties): string {
    return assetUrlTemplate.replace(/{{(\w+)}}/g, (match, prop) => {
        return prop in properties ? String(properties[prop]) : match;
    });
}

/**
 * Given a container and an image, scale the image, while preserving its intrinsic aspect ratio,
 * to the largest size such that both its width and its height can fit inside the container.
 * This partly mimics the the 'background-size: cover' CSS property as specified in the W3C spec:
 * https://drafts.csswg.org/css-backgrounds-3/#valdef-background-size-cover
 * @param containerWidth  Container width.
 * @param containerHeight  Container height.
 * @param imageWidth  Image width.
 * @param imageHeight  Image height.
 * @param opts  Optional parameters.
 */
export function getTargetImageDimensionsForContainer(containerWidth: number, containerHeight: number, imageWidth: number, imageHeight: number, opts?: IImageCoverOptions): ITargetImageDimensions {
    // Build a list of options.
    const options: IImageCoverOptions = { ...defaultImageCoverOptions, ...(!!opts ? opts : {}) };

    // Establish if a height is declared.
    const hasHeight = imageHeight > 0;

    // Calculate the image ratio (default to 1:1 if no height).
    const imageRatio = hasHeight ? imageHeight / imageWidth : 1;

    // Given the ratios derived from the quotients of the container and image dimensions, pick the largest.
    // This will be used to scale the image to fit inside the container whilst honouring its ratio.
    const coverRatio = Math.max(containerWidth / imageWidth, hasHeight ? containerHeight / imageHeight : 0);

    // Calculate the dimensions of the image.
    const scaledWidth = imageWidth * coverRatio;
    const scaledHeight = scaledWidth * imageRatio;

    let proposedWidth = scaledWidth;

    if (options.minVerticalOverflow > 0) {
        // We have the ability to enforce a minimum vertical overflow. This is useful for parallax backgrounds, where we need the height
        // of the image to exceed the height of the container. If an overflow is specified and the sum of the overflow and the
        // container height is greater than the scaled height of the image, re-scale the image dimensions to suit.
        const minHeight = containerHeight + (options.minVerticalOverflow * 2);
        const newHeight = Math.max(scaledHeight, minHeight);

        proposedWidth = newHeight / imageRatio;
    }

    // Apply any scaling.
    proposedWidth = Math.round(proposedWidth * options.scale);

    // Sometimes the proposed image width will exceed the natural image width. We'll want to return this 'safe' value as well.
    const safeWidth = Math.min(proposedWidth, imageWidth);

    return {
        width: proposedWidth,
        height: Math.round(proposedWidth * imageRatio),
        safeWidth: safeWidth,
        safeHeight: Math.round(safeWidth * imageRatio),
        ratio: imageRatio
    };
}

/**
 * Retrieve and parse the image property data attached to an element in the DOM.
 * Returns null if data cannot be parsed, or required properties are missing.
 * @param element  HTML element containing required data.
 */
export function getImagePropsFromDom(element: HTMLElement): IImageProperties {
    // Data should be in the following format:
    // prop:val|prop:val|prop:val...

    const data = element.dataset.bgImageProps;
    const requiredProps = ['fileName', 'naturalWidth'];

    try {
        const imageProps = Object.assign({}, ...data.split('|')
            .map(x => x.split(':'))
            .map(x => ({ [x[0]]: x[1] })));

        for (const prop of requiredProps) {
            if (!imageProps.hasOwnProperty(prop)) {
                throw false;
            }
        }

        return imageProps;
    } catch (e) {
        return null;
    }
}

/**
 * Given a target image width, return an image size either based on the result of the getClosestImageSize() function,
 * or from the image size map should there exist an entry whose width is greater than the aforementioned result.
 * @param key  Image key (filename and extension).
 * @param targetWidth  Target width.
 */
export function getImageSize(key: string, targetWidth: number): ImageSize {
    const minimumImageSize = getClosestImageSize(targetWidth);

    let imageSize = minimumImageSize;

    if (imageSizeMap.has(key)) {
        imageSize = [minimumImageSize, imageSizeMap.get(key)].reduce((prev, curr) => {
            return curr.width > prev.width ? curr : prev;
        });
    }

    return imageSize;
}

/**
 * Given a target width, get the closest match in the global list of available image sizes.
 * @param targetWidth  Target width.
 */
export function getClosestImageSize(targetWidth: number): ImageSize {
    targetWidth *= pixelRatio;

    return imageSizes.reduce((prev, curr) => {
        const a = prev.width < targetWidth;
        const b = curr.width >= targetWidth;
        const c = Math.abs(curr.width - targetWidth) < Math.abs(prev.width - targetWidth);

        return a || (b && c) ? curr : prev;
    });
}

/**
 * Preload an image. Returns a promise which will resolve once the image is loaded.
 * @param paths  Image path to load.
 */
export function preloadImage(path: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>(resolve => {
        const image = new Image();
        const pathTest = /col-(\d+)\/(\w+.jpg|jpeg|gif|png)/.exec(path);

        image.onload = () => {
            if (pathTest !== null && pathTest.length > 1) {
                // It's from the UserContent folder. Cache it!
                updateImageSizeMap(pathTest[2], new ImageSize(pathTest[1], image.naturalWidth));
            }

            resolve(image);
        };

        image.src = path;
    });
}

/**
 * Preload a set of images. Returns a promise which will resolve once all images are loaded.
 * @param paths  Array of image paths to load.
 */
export async function preloadImages(paths: string[]): Promise<HTMLImageElement[]> {
    return new Promise<HTMLImageElement[]>(resolve => {
        Promise.all(paths.map(path => preloadImage(path))).then(images => resolve(images));
    });
}

/**
 * Re-evaluate any images which are utilising srcset, sizes or <picture> elements, but lack browser support.
 * @param images  Array or array-like list of elements. If not defined, all images will be re-evaluated.
 */
export function reevaluate(images?: NodeListOf<HTMLElement> | HTMLElement[]): void {
    if (siteHelpers.picturefillLoaded()) {
        picturefill({
            reevaluate: true,
            elements: typeof images !== 'undefined' && images.length ? images : null
        });
    }
}

/**
 * Add an image size to the image size map, or update existing entry ONLY IF the new image width > currently stored image width.
 * @param key  Image key (filename and extension).
 * @param imageSize  ImageSize object containing the column size and width of the image.
 */
export function updateImageSizeMap(key: string, imageSize: ImageSize): void {
    if (imageSizeMap.has(key)) {
        const storedImageSize = imageSizeMap.get(key);

        imageSize = storedImageSize.width > imageSize.width ? storedImageSize : imageSize;
    }

    imageSizeMap.set(key, imageSize);

    if (useStorage) {
        storage.set(JSON.stringify(mapHelpers.mapToObject(imageSizeMap)));
    }
}
