import { ParallaxType } from '@components/parallax-backgrounds/ParallaxType';
import { Parallax } from './Parallax';

export class ParallaxTiledImage extends Parallax {
    constructor(container: HTMLElement) {
        super(container);

        this.type = ParallaxType.TiledImage;
    }
}
