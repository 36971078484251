import { Method } from '@entities/Method';
import * as ajaxHelpers from '@functions/ajax-helpers';
import * as formHelpers from '@functions/form-helpers';
import * as $ from 'jquery';

interface IResultData {
    result: string;
}

export class CustomForm {
    private readonly form: JQuery<HTMLElement>;
    private readonly action: string;
    private readonly buttonSubmit: JQuery<HTMLElement>;
    private readonly alertFail: JQuery<HTMLElement>;
    private readonly alertSuccess: JQuery<HTMLElement>;

    constructor(element: HTMLElement) {
        this.form = $(element);
        this.action = this.form.attr('action');
        this.buttonSubmit = this.form.find('input[type=submit]');
        this.alertFail = this.form.find('.alert-danger');
        this.alertSuccess = this.form.find('.alert-success');

        this.form.on('submit', event => this.submitHandler(event));
    }

    private async submitHandler(event: JQuery.Event): Promise<void> {
        event.preventDefault();

        if (this.hasValidator && !this.form.valid()) {
            return;
        }

        this.alertSuccess.addClass('hidden');
        this.alertFail.addClass('hidden');

        this.buttonSubmit.buttonLoadingState('show', {
            showSpinner: true
        });

        const data = await ajaxHelpers.xhr<IResultData>(Method.POST, this.action, this.formData);

        if (data.result === 'success') {
            this.alertSuccess.removeClass('hidden');
        } else {
            this.alertFail.removeClass('hidden');
        }

        this.buttonSubmit.buttonLoadingState('hide');
    }

    private get hasValidator(): boolean {
        return formHelpers.checkHasValidator(this.form);
    }

    private get formData(): string {
        return this.form.serialize();
    }
}
