/**
 * Lifted from underscore.js and converted to ES6/TypeScript
 *
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds.
 */
export function debounce(callback: (...args: any[]) => void, time: number): () => void {
    let interval: number;

    return (...args: any[]) => {
        clearTimeout(interval);

        interval = setTimeout(() => {
            interval = null;
            callback(...args);
        }, time);
    };
}
