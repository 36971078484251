import * as Flickity from 'Flickity';
import * as $ from 'jquery';
import { EmbeddedAsset } from './EmbeddedAsset';
import { EmbeddedAssetType } from './EmbeddedAssetType';
import * as siteHelpers from '@functions/site-helpers';
import * as imageHelpers from '@functions/image-helpers';

interface ISelectedSlideData {
    link: string;
    caption: string;
}

const flickityOptionsFixed = {
    adaptiveHeight: false,
    contain: true,
    imagesLoaded: true,
    lazyLoad: true,
    pageDots: true,
    setGallerySize: false,
    wrapAround: true,
    fade: true,
    autoPlay: 5000
};

const flickityOptionsAdaptive = { ...flickityOptionsFixed, ...{
    adaptiveHeight: true,
    lazyLoad: 1, // Load n images either side of the currently selected slide.
    setGallerySize: true
}};

export class CarouselAsset extends EmbeddedAsset {
    private readonly captionContainer: JQuery<HTMLElement>;
    private readonly flickityContainer: JQuery<HTMLElement>;
    private readonly hasCaptionContainer: boolean;
    private readonly isFixedHeight: boolean;

    private flickityInstance?: Flickity;

    constructor(container: HTMLElement) {
        super(EmbeddedAssetType.Carousel, container);

        this.captionContainer = this.container.siblings('.asset-caption');
        this.flickityContainer = this.container.find('.carousel-wrapper');
        this.hasCaptionContainer = !!this.captionContainer.length;
        this.flickityInstance = null;
        this.isFixedHeight = this.flickityContainer.hasClass('embed-responsive-item');
    }

    public create(): void {
        this.flickityContainer.flickity(this.isFixedHeight ? flickityOptionsFixed : flickityOptionsAdaptive);
        this.flickityInstance = Flickity.data(this.flickityContainer.get(0));
        this.isInitialised = true;

        if (this.hasCaptionContainer) {
            this.showCaptionForSelectedSlide();

            this.flickityContainer.on('select.flickity', () => this.showCaptionForSelectedSlide());
        }

        if (!this.isFixedHeight && !siteHelpers.browser.isIE11) {
            // If the carousel has adaptive height enabled then the offset of any succeeding elements in the DOM
            // will potentially change when a new slide is selected. We'll dispatch a resize event which can be
            // registered by any listening components and they can act accordingly.
            this.flickityContainer.on('settle.flickity', () => window.dispatchEvent(new Event('resize')));
        }

        if (siteHelpers.browser.isIE11) {
            this.flickityContainer.on('select.flickity', () => imageHelpers.reevaluate());
        }
    }

    public destroy(): void {
        this.flickityContainer.flickity('destroy').off('select.flickity settle.flickity');
        this.flickityInstance = null;
        this.isInitialised = false;

        if (this.hasCaptionContainer) {
            this.captionContainer.empty();
        }
    }

    private showCaptionForSelectedSlide(): void {
        const { link, caption } = this.selectedSlideData;

        if (caption !== '') {
            this.captionContainer.html(link !== '' ? `<a href="${link}">${caption}</a>` : caption);
        } else {
            this.captionContainer.empty();
        }
    }

    private get selectedSlideData(): ISelectedSlideData {
        const selectedSlide = $(this.flickityInstance.selectedElement);

        return {
            link: (selectedSlide.find('a').attr('href') || '').trim(),
            caption: (selectedSlide.find('img').data('caption') || '').trim()
        };
    }
}
