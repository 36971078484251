import * as imageHelpers from '@functions/image-helpers';
import * as siteHelpers from '@functions/site-helpers';
import { debounce } from '@utils/debounce';
import * as $ from 'jquery';
import { ImageContainer } from './ImageContainer';

namespace AdaptiveBgImages {
    const bgImageRepository = [...$('.bg-adaptive').get().map(x => new ImageContainer(x))];

    function globalResize(): void {
        bgImageRepository.forEach(container => {
            container.setContainerDimensions();

            const imageSize = imageHelpers.getImageSize(container.imageHelper.key, container.imageHelper.targetDimensions.safeWidth);

            container.imageHelper.load(imageSize);
            imageHelpers.updateImageSizeMap(container.imageHelper.key, imageSize);
        });
    }

    export function init(): void {
        if (bgImageRepository.length) {
            globalResize();

            window.addEventListener('resize', debounce(globalResize, 200), siteHelpers.usePassiveIfSupported);
        }
    }
}

AdaptiveBgImages.init();
